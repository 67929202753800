import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Application from './components/Application';
import Articles from './components/Articles';
function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path='/' element={<Application />}/>
          <Route path='/:articles' element={<Articles/>}/>
          <Route path='*' element={""}/>
        </Routes>
      </Router>
    </>
  );
}

export default App;
