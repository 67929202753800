import React from "react";
import icon1 from './img/icon1.png'
import icon2 from './img/icon2.png'
import icon3 from './img/icon3.png'
import icon4 from './img/icon4.png'
import icon5 from './img/icon5.png'
import icon6 from './img/icon6.png'
import sectionImg from './img/section.png'
import trelloImg from './img/trello.png'
import flappybirdImg from './img/Bird.png'
import wanderlustImg from './img/wanderlust.png'
const Content = () => {
    return(
        <>
           <div className="main"id="modules">
                <div className="container">
                    <div className="text-content">
                        <h1>LOOPING DORK'S LKS TRAINING MODULE</h1>
                        <p>SIMPLY SOURCE CODE</p>
                    </div>
                    <div className="source text-center mt-5">
                        <div className="row justify-content-center">
                            <div className="col-md-4">
                                <img className="imgs"src={icon1} alt="" height={150} width={150} />
                                <h4 className="mt-2 mb-5">SPEEDTEST MODULE</h4>
                            </div>
                            <div className="col-md-4">
                                <img className="imgs"src={icon2} alt="" height={150} width={150}/>
                                <h4 className="mt-2 mb-5">SNAKE GAME MODULE</h4>
                            </div>
                            <div className="col-md-4">
                                <img className="imgs"src={icon3} alt="" height={150} width={150}/>
                                <h4 className="mt-2 mb-5">CUBE ROTATION MODULE</h4>
                            </div>
                            <div className="col-md-4">
                                <img className="imgs"src={icon4} alt="" height={150} width={150}/>
                                <h4 className="mt-2 mb-5">VACCINATION MODULE</h4>
                            </div>
                            <div className="col-md-4">
                                <img className="imgs"src={icon5} alt="" height={150} width={150}/>
                                <h4 className="mt-2 mb-5">FRAMERORK CRUD MODULE</h4>
                            </div>
                            <div className="col-md-4">
                                <img className="imgs"src={icon6} alt="" height={150} width={150}/>
                                <h4 className="mt-2 mb-5">VIRUS SLAYER GAME MODULE</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section" id="section">
                <div className="container">
                    <div className="row ">
                        <div className="col-md-6">
                            <img src={sectionImg} className="sectionImg" alt="img code" />
                        </div>
                        <div className="col-md-6">
                            <div className="card">
                                <div className="card-body competition">
                                    <h4>LOOPING DORK'S LKS COMPETITION MODULE</h4>
                                    <hr />
                                    <div className="row mb-4">
                                        <div className="col-md-4">
                                            <img src={trelloImg} alt="" height={100} width={100} />
                                        </div>
                                        <div className="col-md-4">
                                            <h5>MODUL SERVER</h5>
                                            <p>TRELLO CLONE</p>
                                        </div>
                                    </div>
                                    <div className="row mb-4">
                                        <div className="col-md-4">
                                            <img src={flappybirdImg} alt="" height={100} width={100} />
                                        </div>
                                        <div className="col-md-6">
                                            <h5>MODUL CLIENT</h5>
                                            <p>FLAPPY BIRD</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <img src={wanderlustImg} alt="" height={100} width={100} />
                                        </div>
                                        <div className="col-md-6">
                                            <h5>MODUL WORDPRESS</h5>
                                            <p>WANDERLUST ADVENTURES</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                         
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Content;