import React from "react";
import { Card, Badge, Accordion } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faNewspaper, faCubes, faHashtag, faDesktopAlt } from '@fortawesome/free-solid-svg-icons'
import { Link } from "react-router-dom";
import articleImgSql from './img/sql.jpg'
import articleImg from './img/artikel1.png'
import articleImg2 from './img/artikel2.jpg'
import articleImg3 from './img/artikel3.jpg'
import articleImg4 from './img/artikel.jpg'
import post1 from './img/post1.jpg'
import post2 from './img/post2.jpg'
import post3 from './img/post3.jpg'
const SideNav = () => {
    const fetchAlertWarning = () => {
        alert('Ga bisa buka? tanya mimin!')
    }
    return(
        <>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-8">
                        <div className="card"style={{marginTop:"80px"}}>
                            <div className="card-body">
                                <div className="left">
                                    <h4 id="articles"><FontAwesomeIcon icon={faNewspaper} /> ARTIKEL TERKAIT</h4>
                                    <hr />
                                    <div className="row ">
                                        <div className="col-md-4">
                                            <Card style={{ width: '100%' }}>
                                                <Card.Img variant="top" src={articleImgSql} />
                                                <Card.Body>
                                                    <Card.Title>SQL</Card.Title>
                                                    <Card.Text>
                                                        <Link
                                                            style={{textDecoration:"none"}} 
                                                            to={{
                                                            pathname:"./articles1"
                                                        }}>
                                                            MEMBUAT DATABASE MAHASISWA + TABEL ADMIN DAN TABEL SISWA MENGGUNAKAN MONGODB
                                                        </Link>
                                                    </Card.Text>
                                                    <Badge className="mt-2 bg-info">Updated</Badge>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <Card style={{ width: '100%' }}>
                                                <Card.Img variant="top" src={articleImg}/>
                                                <Card.Body>
                                                    <Card.Title>PHP CRUD</Card.Title>
                                                    <Card.Text>
                                                        <Link
                                                            style={{textDecoration:"none"}} 
                                                            to={{
                                                            pathname:"./articles2"
                                                        }}>
                                                            MEMBUAT HALAMAN SESSION LOGIN,LOGOUT,REGISTER MENAMPILKAN
                                                            USER DASHBOARD + <small>CONFIGURATION</small>
                                                        </Link>
                                                    </Card.Text>
                                                    <Badge className="mt-2 bg-info">Updated</Badge>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                        <div className="col-md-4">
                                            <Card style={{ width: '100%' }}>
                                                <Card.Img variant="top" src={articleImg} />
                                                <Card.Body>
                                                    <Card.Title>PHP CRUD</Card.Title>
                                                    <Card.Text>
                                                        <Link
                                                            style={{textDecoration:"none"}} 
                                                            to={{
                                                            pathname:"./articles3"
                                                        }}>
                                                            MEMBUAT DATA SISWA CREATE, READ, UPDATE, DELETE DI HALAMAN DASBHOARD + <small>MYSQLI </small> 
                                                        </Link>
                                                    </Card.Text>
                                                    <Badge className="mt-2 bg-info">Updated</Badge>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                        
                                        <div className="col-md-4">
                                            <Card style={{ width: '100%' }}>
                                                <Card.Img variant="top" src={articleImg} />
                                                <Card.Body>
                                                    <Card.Title>PHP CRUD</Card.Title>
                                                    <Card.Text>
                                                        <Link
                                                            onClick={() => fetchAlertWarning()}
                                                            style={{textDecoration:"none"}} 
                                                            to={{
                                                            // pathname:"./articles3"
                                                        }}>
                                                            MEMBUAT LOGIN MULTI USER. 
                                                        </Link>
                                                    </Card.Text>
                                                    <Badge className="mt-2 bg-info">Updated</Badge>
                                                </Card.Body>
                                            </Card>
                                        </div>

                                        <div className="col-md-4 mb-3">
                                            <Card style={{ width: '100%' }}>
                                                <Card.Img variant="top" src={articleImg} />
                                                <Card.Body>
                                                    <Card.Title>PHP CRUD</Card.Title>
                                                    <Card.Text>
                                                        <Link
                                                            onClick={() => fetchAlertWarning()}
                                                            style={{textDecoration:"none"}} 
                                                            to={{
                                                            // pathname:"./articles3"
                                                        }}>
                                                            MEMBUAT JOIN TABLE 
                                                        </Link>
                                                    </Card.Text>
                                                    <Badge className="mt-2 bg-info">Updated</Badge>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                        <div className="col-md-4">
                                            <Card style={{ width: '100%' }}>
                                                <Card.Img variant="top" src={articleImg} />
                                                <Card.Body>
                                                    <Card.Title>PHP CRUD</Card.Title>
                                                    <Card.Text>
                                                        <Link
                                                            onClick={() => fetchAlertWarning()}
                                                            style={{textDecoration:"none"}} 
                                                            to={{
                                                            // pathname:"./articles3"
                                                        }}>
                                                            KONFIRMASI PASSWORD
                                                        </Link>
                                                    </Card.Text>
                                                    <Badge className="mt-2 bg-info">Updated</Badge>
                                                </Card.Body>
                                            </Card>
                                        </div>

                                        <div className="col-md-4">
                                            <Card style={{ width: '100%' }}>
                                                <Card.Img variant="top" src={articleImg4} height={180} />
                                                <Card.Body>
                                                    <Card.Title>JS CODE</Card.Title>
                                                    <Card.Text>
                                                        CREATE JSO GENERATOR
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                        <div className="col-md-4">
                                            <Card style={{ width: '100%' }}>
                                                <Card.Img variant="top" src={articleImg2} height={180}/>
                                                <Card.Body>
                                                    <Card.Title>ITO PROJECT</Card.Title>
                                                    <Card.Text>
                                                        CREATE RESPONSIVE DESIGN 
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                        <div className="col-md-4">
                                            <Card style={{ width: '100%' }}>
                                                <Card.Img variant="top" src={articleImg3} height={180}/>
                                                <Card.Body>
                                                    <Card.Title>PYGAME</Card.Title>
                                                    <Card.Text>
                                                        CREATE SIMPLE GAME
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card" style={{marginTop:"40px"}}id="newsletter">
                            <div className="card-body">
                                <h4><FontAwesomeIcon icon={faDesktopAlt} /> NEWSLETTER</h4>
                                <hr />
                                <div className="newsletter">
                                    <h4 style={{letterSpacing:"0.8rem"}}>SUBSCRIBE OUR NEWSLETTER</h4>
                                    <input type="text" className="form-control" placeholder="read only" readOnly/>
                                    <button className="btn btn-info mt-3"style={{width:"50%"}}>SENT</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card"style={{marginTop:"80px"}}>
                            <div className="card-body">
                                <h4><FontAwesomeIcon icon={faCubes} /> POST LAINNYA</h4>
                                <hr />
                                <img src={post1} alt="" style={{width:"100%"}}/>
                                <div className="post mt-3 mb-3">
                                    <div className="d-flex justify-content-between">
                                        <h4>OTHER POST</h4>
                                        <p>POST BY <Badge>Me</Badge></p>
                                    </div>
                                    <p>JUST POST</p>
                                    <p><Badge bg="warning">POST 1</Badge></p>
                                </div>
                                <img src={post2} alt="" style={{width:"100%"}}/>
                                <div className="post mt-3 mb-3">
                                    <div className="d-flex justify-content-between">
                                        <h4>OTHER POST</h4>
                                        <p>POST BY <Badge>Me</Badge></p>
                                    </div>
                                    <p>JUST POST</p>
                                    <p><Badge bg="danger">POST 2</Badge></p>
                                </div>
                                <img src={post3} alt="" style={{width:"100%"}}/>
                                <div className="post mt-3 mb-3">
                                    <div className="d-flex justify-content-between">
                                        <h4>OTHER POST</h4>
                                        <p>POST BY <Badge>Me</Badge></p>
                                    </div>
                                    <p>JUST POST</p>
                                    <p><Badge bg="success">POST 3</Badge></p>
                                </div>
                            </div>
                        </div>
                        <div className="card"style={{marginTop:"30px"}}>
                            <div className="card-body">
                                <h4><FontAwesomeIcon icon={faHashtag} /> TAGS</h4>
                                <hr />
                                <div className="row">
                                    <div className="col-md-3">
                                        <Badge>
                                            PHP CRUD
                                        </Badge>
                                        <div className="col-md-3">
                                            <Badge bg="success">
                                                MODULES
                                            </Badge>
                                        </div>
                                        <div className="col-md-3">
                                            <Badge bg="warning"> 
                                                MYSQLI
                                            </Badge>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <Badge bg="danger">
                                            SPEED TEST
                                        </Badge>
                                        <div className="col-md-3">
                                            <Badge bg="info">
                                                SNAKE GAME
                                            </Badge>
                                        </div>
                                        <div className="col-md-3">
                                            <Badge bg="secondary">
                                                SLAYER GAME
                                            </Badge>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <Badge bg="info">
                                            VACCINATION APP
                                        </Badge>
                                        <div className="col-md-3">
                                            <Badge bg="success">
                                                CUBE ROTATION
                                            </Badge>
                                        </div>
                                        <div className="col-md-3">
                                            <Badge bg="dark">
                                                MONGODB
                                            </Badge>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card"style={{marginTop:"30px"}}>
                            <div className="card-body">
                                <h4><strong>?</strong> FREQUENTLY AS QUESTION</h4>
                                <hr />
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>WHY CHOOSE BLUE BACKGROUND </Accordion.Header>
                                        <Accordion.Body>
                                        Cause blue symbolized Productivity and Confidence
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>WHY CREATE THIS APPLICATION</Accordion.Header>
                                        <Accordion.Body>
                                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro, natus.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default SideNav;