import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
const Footer = () => {
    return(
        <>
            <div className="footer bg-white py-5 mt-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <h4>QUICK LINKS</h4>
                            <p><a href="#"style={{textDecoration:"none"}}><FontAwesomeIcon icon={faArrowRight} /> ARTICLES</a></p>
                            <p><a href="#"style={{textDecoration:"none"}}><FontAwesomeIcon icon={faArrowRight} /> MODULES</a></p>
                            <p><a href="#"style={{textDecoration:"none"}}><FontAwesomeIcon icon={faArrowRight} /> NEWSLETTERS</a></p>
                        </div>
                        <div className="col-md-3">
                            <h4>TOP MODULES</h4>
                            <p><a href="#"style={{textDecoration:"none"}}><FontAwesomeIcon icon={faArrowRight} /> SERVER SIDE MODULES</a></p>
                            <p><a href="#"style={{textDecoration:"none"}}><FontAwesomeIcon icon={faArrowRight} /> GAME MODULES</a></p>
                            <p><a href="#"style={{textDecoration:"none"}}><FontAwesomeIcon icon={faArrowRight} /> SPEED TEST MODULES</a></p>
                        </div>
                        <div className="col-md-3">
                            <h4>TOP POST</h4>
                            <p><a href="#"style={{textDecoration:"none"}}><FontAwesomeIcon icon={faArrowRight} /> PHP CRUD</a></p>
                            <p><a href="#"style={{textDecoration:"none"}}><FontAwesomeIcon icon={faArrowRight} /> SIMPLE PYGAME</a></p>
                            <p><a href="#"style={{textDecoration:"none"}}><FontAwesomeIcon icon={faArrowRight} /> JSO CODE</a></p>
                        </div>
                        <div className="col-md-3">
                            <h4>FOLLOW US</h4>
                            <p><a href="#"style={{textDecoration:"none"}}><FontAwesomeIcon icon={faArrowRight} /> GITHUB</a></p>
                            <p><a href="#"style={{textDecoration:"none"}}><FontAwesomeIcon icon={faArrowRight} /> GIT</a></p>
                            <p><a href="#"style={{textDecoration:"none"}}><FontAwesomeIcon icon={faArrowRight} /> LINKEDIN</a></p>
                        </div>
                    </div>
                    <div className="text-center mt-5">
                        <p style={{textTransform:"uppercase"}}>Copy-right &copy; All Right Reserved 2023</p>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Footer;