import imgCRUD from './img/banner.png'
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBookOpen} from '@fortawesome/free-solid-svg-icons'
import '../App.css';
const Main = () => {
    return(
        <>
            <div className="jumbotron-fluid">
                <div className="container"style={{marginTop:"30px"}}>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="content">
                                <h3><span className='text'>LOOPING</span> DORK</h3>
                                <p className='mt-3'>
                                    LOOPING DORK IS A WEB DESIGN LEARNING MEDIA APPLICATION FOR COMPETITIONS SUCH AS LKS MODULE, ITO, & OTHER ARTICLES
                                </p>
                                <a className="link"href="#modules">
                                    <FontAwesomeIcon icon={faBookOpen} />
                                   &nbsp; READ MORE
                                </a>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="image">
                                <img className="image"src={imgCRUD} alt="" style={{width:"100%"}}/>
                            </div>
                        </div>
                    </div>                   
                </div>
            </div>
        </>
    )
}
export default Main;