import {Container, Nav, Navbar} from "react-bootstrap"
import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCodeCompare, faUsersViewfinder } from '@fortawesome/free-solid-svg-icons'
import '../App.css';
const Navs = () => {
    return(
        <>
             <Navbar collapseOnSelect expand="lg"className="navbar" fixed="top">
                <Container>
                    <Navbar.Brand href="./"><FontAwesomeIcon icon={faCodeCompare} /> LOOPING DORK</Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto"></Nav>
                    <Nav className="me-auto">
                        <Nav.Link href="./#articles">ARTICLES</Nav.Link>
                        <Nav.Link href="./#modules">MODULES</Nav.Link>
                        <Nav.Link href="./#newsletter">NEWSLETTER</Nav.Link>
                    </Nav>
                    <Nav>
                        <Nav.Link><FontAwesomeIcon icon={faUsersViewfinder} /> FOR GRADE 11 RPL</Nav.Link>
                    </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    )
}
export default Navs;