import React from "react";
import '../App.css';
import Navs from "./Navs";
import Main from "./Main";
import Content from "./Content";
import SideNav from "./SideNav";
import Footer from "./Footer";
const Application = () => {
    return(
        <div className="min-vh-100 body">
            <Navs />
            <Main />
            <Content />
            <SideNav />
            <Footer />
        </div>
    )
}
export default Application;